`import {init_issue_datetime_picker, init_general_datetime_picker, init_general_date_picker, _init_simple_date_and_time_masks, init_filters_date_picker} from 'javascripts/main/datetime_initializers'`
`import 'javascripts/best_in_place/best_in_place'`
`import CustomSelect from 'javascripts/vendors/custom_select'`
`import CustomCheckbox from 'javascripts/vendors/custom_checkbox'`
`import MultilevelSelect from 'javascripts/vendors/multilevel_select'`
`import Turbolinks from 'javascripts/turbolinks'`

perform_json_response = (json_data) ->
  methods = ['html', 'append', 'prepend', 'after', 'before', 'replaceWith', 'remove']

  if json_data?
    for key, method of methods
      for k, response_type of ['html_content', 'error', 'custom_flash']
        if json_data.hasOwnProperty(response_type) && json_data[response_type].hasOwnProperty(method)
          apply_json_response(json_data[response_type][method], method)
          scroll_to_flash(json_data[response_type]) if response_type == 'custom_flash'

    if json_data.redirect? && json_data.redirect.hasOwnProperty('redirect_path')
      Turbolinks.visit(json_data.redirect.redirect_path)

    if json_data.hasOwnProperty('redirect_location')
      window.location.href = json_data.redirect_location;

    if json_data.hasOwnProperty('data_attributes')
      for selector, attributes of json_data.data_attributes
        for attribute, value of attributes
          $(selector).data(attribute, value)

    if json_data.hasOwnProperty('input_values')
      for selector, value of json_data.input_values
        str_value = value.toString()
        continue if $(selector).val() == str_value
        if $(selector).is('@custom-select')
          CustomSelect.set_value(selector, str_value, false)
        else
          $(selector).val(str_value)
    if json_data.hasOwnProperty('field_errors')
      field_errors_response(json_data.field_errors, json_data.model)
    if json_data.hasOwnProperty('form_errors')
      form_errors_response(json_data.form_errors)

scroll_to_flash = (json_data) ->
  unless json_data['without_scroll']
    top = $("@flash-container").offset().top - $("@flash-container").height()
    $('html, body').animate({ scrollTop: top }, '500')
  setTimeout (-> $('.notification-line').slideUp()), 10000

init_custom_control = (inserted_selector) ->
  custom_controls = ['@custom-select', '@issue-datetime-picker', '@datetime-picker', '@date-picker',
                     '@filter-data-picker', '@custom-modal-select', '@custom-checkbox', '@multilevel-select',
                     '.best_in_place']
  for key, control_selector of custom_controls
    if custom_element = $(inserted_selector).find(control_selector)
      switch control_selector
        when '@custom-select'
          if custom_element.hasClass('filters-ms')
            CustomSelect.init custom_element,
              onclose: (select) ->
                if select.attr('class') == 'filters-ms'
                  select.closest('form').submit()
          else
            CustomSelect.init(custom_element)
        when '@custom-modal-select' then CustomSelect.init(custom_element)
        when '@issue-datetime-picker' then init_issue_datetime_picker()
        when '@datetime-picker' then init_general_datetime_picker()
        when '@date-picker' then init_general_date_picker()
        when '@filter-data-picker' then init_filters_date_picker()
        when '@custom-checkbox' then CustomCheckbox.init(control_selector)
        when '@multilevel-select' then MultilevelSelect.init(custom_element)
        when '.best_in_place' then $(custom_element).best_in_place()

apply_json_response = (prop_array, method) ->
  for selector, html of prop_array
    $(selector)[method](html)
    init_custom_control(selector)

field_errors_response = (struct, model) ->
  form = $(document).find("form[id*='#{model.name}']")
  form.find('.error').remove()
  form.find('.form-group').removeClass('field_with_errors')
  Object.keys(struct.errors).forEach (objectKey, index) ->
    element_name = "#{model.name}[#{objectKey}]"
    element = $(document).find("input[name='#{element_name}'], input[name='#{element_name}[]'],
                                select[name='#{element_name}'], select[name='#{element_name}[]']")
    if element.length > 0
      form_group = element.closest('.form-group')
    else
      element = $(document).find("input[name='#{element_name}[date]']")
      form_group = element.closest('.fields-set')

    objectErrors = struct.errors[objectKey]
    errorsMessages = if Array.isArray(objectErrors) then objectErrors.join(', ') else objectErrors

    form_group.addClass('field_with_errors')
    form_group.append("<span class='error'>#{errorsMessages}</span>")

form_errors_response = (errors_data) ->
  return if !errors_data.messages

  form = $("form@#{errors_data.form_role}")
  errors = errors_data.messages

  return if form.length == 0

  Object.keys(errors).forEach (messageKey, index) ->
    add_errors_to_form(form, errors[messageKey])

add_errors_to_form = (form, message) ->
  error_block = form.find('.form-error-block')
  form_group = error_block.closest('.form-group').removeClass('field_with_errors')
  form_group.find('.error').remove()
  form_group.addClass('field_with_errors')
  if message[0].length > 50
    form_group.append("<span class='error large-message'>#{message}</span>")
  else
    form_group.append("<span class='error'>#{message}</span>")

`export default perform_json_response`
